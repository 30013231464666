import React from 'react'

//Styles
import "./ShowAcademic.scss"

//Components
import Card from '../../../components/atoms/Card/Card'
import Back from '../../../components/atoms/Back/Back'
import { Skeleton } from 'primereact/skeleton'

const ShowAcademicSkeleton = () => {
  return (
    <Card classNameInside='ShowAcademic'>
      <Back className={"bAcks_"} skeleton/>
      <Skeleton width='400px' height='20px'/>
      {[...Array(7)].map((_, idx) => (
        <div key={idx} style={{display: "flex", flexDirection: "column", gap: 13}}>
            <Skeleton width='100px' height='15px'/>
            <Skeleton width='250px' height='15px'/>
        </div>
      ))}
    </Card>
  )
}

export default ShowAcademicSkeleton