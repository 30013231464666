import React from 'react'

import "./NewQuestion.scss"
import Card from '../../../components/atoms/Card/Card'
import Input from '../../../components/atoms/Input/Input'
import Button from '../../../components/atoms/Button/Button'
import { ColorValidation, SubmitValidation, UpdateValue } from '../../../utilities/Validations'
import { useState } from 'react'
import { useEffect } from 'react'
import AnimationLoading from '../../../components/atoms/AnimationLoading/AnimationLoading'
import { CorrectModal } from '../../../components/molecules/modals/Modals'
import Back from '../../../components/atoms/Back/Back'
import { Skeleton } from 'primereact/skeleton'

const NewQuestionSkeletons = () => {

    const [loading, setLoading] = useState(false)
    const [inputList, setInputList] = useState({
        question: { value: null, validationType: "empty" },
        answer: { value: null, validationType: "empty" },
    });

    useEffect(() => {
        for (const propertyName in inputList) {
          if (document.getElementById(propertyName)) {
            ColorValidation(propertyName, inputList);
          }
        }
    }, [inputList]);

    const handleSubmit = () => {
        const validated = SubmitValidation(inputList, setInputList)

        if(validated){
            setLoading(true)
            setTimeout(() => {
                CorrectModal("¡Nueva pregunta agregada!")
                setLoading(false)
            }, 3000)

        }
    }



    return (
        <Card className={"NewQuestion"} classNameInside='Inside-NewQuestion'>
            <Back className={"b_tn_back1"}  skeleton/>
            <Skeleton width='200px' height='25px'/>
            <section className='secct1_create'>
                <Input skeleton />
                <Input skeleton />
            </section>
            
            <Skeleton width='230px' height='40px'/>
            
        </Card>
    )
}

export default NewQuestionSkeletons