//styles
import './Sidebar.scss'

//components
import sidebar from "../../../assets/images/Sidebar.svg"

//react
import { NavLink } from 'react-router-dom'

//Assets
import iconEvents from '../../../assets/images/event.png'
import News from '../../../assets/images/news.png'
import iconCommittee from '../../../assets/images/cog.png'
import Shield from '../../../assets/images/shield.png'
import Academic from '../../../assets/images/academic.png'
import Audience from '../../../assets/images/audience.png'
import Scientific from '../../../assets/images/scientific.png'
import chart from '../../../assets/images/chart.png'
import Book from '../../../assets/images/book.png'

const Sidebar = () => {
    const routes = [
        { title: "Métricas", url: "/dashboard", icon: chart },
        { title: "Control de artículos", url: "/article-control", icon: News },
        { title: "Números actuales", url: "/current-numbers", icon: Scientific },
        { title: "Números anteriores", url: "/journals", icon: Book },
        { title: "Académicos", url: "/academics", icon: Academic },
        { title: "Administradores", url: "/administrators", icon: iconCommittee },
        { title: "Comité", url: "/committee", icon: Audience },
        { title: "Eventos", url: "/next-events", icon: iconEvents },
        { title: "Legales", url: "/legals", icon: Shield },
    ]

    return (
        <aside className='AuthSidebar Sidebar'>
            <div className='inside_lk'>
                <div className='logo'>
                    <img src={sidebar} className='logo_sidebar'/>
                </div>
                <div className='cnt_options'>
                    <div className='options'>
                    {
                        routes.map((option, index) => {
                            if (option.title === "Cerrar sesión") {
                            return (
                                <NavLink
                                key={index}
                                className="option"
                                to={option.url}
                                onClick={handleLogOut}
                                >
                                {option.icon && <img src={option.icon} className='icon_n' />}
                                {option.title}
                                </NavLink>
                            );
                            } else {
                            return (
                                <NavLink
                                key={index}
                                className="option"
                                to={option.url}
                                >
                                {option.icon && <img src={option.icon} className='icon_n' />}
                                {option.title}
                                </NavLink>
                            );
                            }
                        })
                    }
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Sidebar