// react
import { Skeleton } from "primereact/skeleton";

// styles
import "./TableCardX.scss";
// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const TableCardX = ({
    onRowReorder,
    data,
    columns,
    numberRows,
    classInside,
    skeleton = false,
    paginator = false,
    classTable = "",
    emptyMessage="No se encontraron resultados"
}) => {
    return (
        <div className={`TableCard ${skeleton && "TableCardSkeleton"} ${classInside}`}>
                <DataTable
                    onRowReorder={onRowReorder}
                    value={data}
                    responsiveLayout="scroll"
                    className={classTable}
                    stripedRows
                    emptyMessage={emptyMessage}
                    paginator={paginator}
                    rows={numberRows ? parseInt(numberRows) : 11}
                    scrollable
                    scrollHeight={numberRows > 12 ? "600px" : ""}
                >
                    {columns?.map((e) => (
                        <Column
                            rowReorder={e?.reorder}
                            key={e?.id}
                            field={e?.field}
                            style={{width:e.w ? e.w : "100%"}}
                            header={
                                !skeleton ? (
                                    e?.header
                                ) : (
                                    <Skeleton width={e.w} height="15px" />
                                )
                            }
                            body={
                                !skeleton ? (
                                    e?.body ? (
                                        e?.body
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    <Skeleton width="80%" height="25px" />
                                )
                            }
                            className={`columnStyle ${e?.bold && "-fw500"}`}
                            frozen={e.frozen}
                        ></Column>
                    ))}
                </DataTable>
        </div>
    );
};
export default TableCardX;
