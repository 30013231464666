import React from 'react'

import "./AuthLayout.scss"
import Footer from '../../molecules/Footer/Footer'
import Sidebar from '../../molecules/Sidebar/Sidebar'
import Header from '../../molecules/Header/Header'

const AuthLayout = ({ children }) => {
  return (
    <div className="AuthLayout">
      <Header />
      <Sidebar />
      {children}
      <Footer />
    </div>
  )
}

export default AuthLayout