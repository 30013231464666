import React from "react";

//Styles
import "./NewCommittee.scss";
import Card from "../../../components/atoms/Card/Card";
import { Skeleton } from "primereact/skeleton";

const NewCommitteeSkeletons = () => {
  return (
    <Card className={"NewCommittee"}>
      <Skeleton width="180px" height="25px"/>
      <div className="secct1_">
        <div style={{width: 168, height: 168, borderRadius: "50%", border: 1, borderStyle: "solid", borderColor: "##b7b7b7"}}></div>
        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
            <Skeleton width="80px" height="10px"/>
            <Skeleton width="227px" height="48px"/>
        </div>
      </div>
      <div className="secct2_">
        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
            <Skeleton width="80px" height="10px"/>
            <Skeleton width="100%" height="48px"/>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
            <Skeleton width="80px" height="10px"/>
            <Skeleton width="100%" height="48px"/>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 8}}>
            <Skeleton width="80px" height="10px"/>
            <Skeleton width="100%" height="48px"/>
        </div>
      </div>
      <div className="secct3_">
        <div className="cnt_btns">
            <Skeleton width="100%" height="45px"/>
            <Skeleton width="100%" height="45px"/>
        </div>
      </div>
    </Card>
  );
};

export default NewCommitteeSkeletons;
