import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./index.css";

import { ContextProvider } from "./context/ContextProvider";

import Auth from './routes/Auth/Auth'
import Guest from "./routes/Guest/Guest";

// global styles"
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import { useEffect } from "react";
// import Footer from "./components/molecules/Footer/Footer";

export default function RootRoute() {
  const [auth, setAuth] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token")
    if(token){
      setAuth(true)
    }
  }, [])
  return (
    <main>
      <BrowserRouter>
          {auth ? <Auth /> : <Guest setAuth={setAuth}/>}
      </BrowserRouter>
    </main>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ContextProvider>  
    <React.StrictMode>
      <RootRoute />
    </React.StrictMode>
  </ContextProvider>
);
