import React from "react";
//styles
import "./CurrentIssue.scss";
import Card from "../../components/atoms/Card/Card";
import { Skeleton } from "primereact/skeleton";

const CurrentIssueSkeletons = () => {
  return (
    <Card className="CurrentIssue">
        <div className="header_table">
            <Skeleton width="300px" height="40px"/>
            <div className="cnt_btn-search">
                <Skeleton width="100%" height="48px"/>
                <Skeleton width="100%" height="48px"/>
            </div>
        </div>
        <div className="cnt_table">
            <div style={{width: "100%", height: 600, display: "flex", flexDirection: "column", border: 1, borderStyle: "solid", borderColor: "#d9d9d9"}}>
                <div style={{width: "100%", height: 50, display: "flex", alignItems: "center", paddingLeft: 40, paddingRight: 40, justifyContent: "space-between", borderBottom: 1, borderBottomStyle: "solid", borderColor: "#d9d9d9" }}>
                    {[...Array(6)].map((_, ind) => (
                        <Skeleton key={ind} width="100px" height="15px"/>
                    ))}
                </div>
                {[...Array(5)].map((_, idx) => (
                    <div key={idx} style={{width: "100%", height: 100, display: "flex", alignItems: "center", paddingLeft: 40, paddingRight: 40, justifyContent: "space-between", borderBottom: 1, borderBottomStyle: "solid", borderColor: "#d9d9d9" }}>
                        {[...Array(6)].map((_, ind) => (
                            <Skeleton key={ind} width="100px" height="15px"/>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </Card>
  );
};

export default CurrentIssueSkeletons;
