import React from "react";
import './Metrics.scss'
import Card from "../../components/atoms/Card/Card";



const Metrics = () => {
    return (
        <>

            <Card >
                <div className="Metrics">
                    <div className="in-progress">MÉTRICAS EN PROGRESO</div>
                </div>
            </Card>
        </>
    )
}
export default Metrics
