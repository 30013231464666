import React from "react";
import CommitteeRoutes from "./CommitteeRoutes/CommitteeRoutes";
import MagazineRoutes from "./MagazineRoutes/MagazineRoutes";
import CurrentIssueRoutes from './CurrentIssue/CurrentIssueRoutes'
// import Error404 from '../../pages/Error404/Error404'
// import Error404Routes from './Error404Routes/Error404Routes'
import NextEventsRoutes from "./NextEventsRoutes/NextEventsRoutes";
import AuthLayout from "../../components/organims/AuthLayout/AuthLayout";
import AcademicRoutes from "./AcademicRoutes/AcademicRoutes";
import AdministratorRoutes from "./AdministratorRoutes/AdministratorRoutes";
import ArticleControlRoutes from "./ArticleControlRoutes/ArticleControlRoutes";
import Metrics from "./MetricsRoutes/Metrics";
import MetricsRoutes from "./MetricsRoutes/Metrics";
import LegalsRoutes from "./LegalsRoutes/LegalsRoutes";

const Auth = () => {
  return (
    <AuthLayout>
      <MetricsRoutes/>
      <CurrentIssueRoutes />
      <CommitteeRoutes />
      <MagazineRoutes />
      <NextEventsRoutes />
      <AcademicRoutes />
      <AdministratorRoutes />
      <ArticleControlRoutes />
      <LegalsRoutes />
    </AuthLayout>
  )
}

export default Auth;
