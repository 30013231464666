import React, { useState, useRef, useEffect } from 'react';

//styles
import "./Header.scss"



const Header = () => {
  const [showMyProfile, setShowMyProfile] = useState(false);
  const storedUserDatas = localStorage.getItem('userDatas');
  const userDatas = JSON.parse(storedUserDatas);
  const profileRef = useRef();

  const handleLogOut = () => {
    const itemsToRemove = [
      'token',
      'username',
      'auth',
      'tknStge',
      'jeyaiodl',
      'userDatas'
    ];
    itemsToRemove.forEach((item) => {
      localStorage.removeItem(item);
    });
    window.location.replace('/');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileRef.current &&
        !profileRef.current.contains(event.target) &&
        event.target.className !== 'avatar' &&
        event.target.className !== 'image_avatar' &&
        event.target.className !== 'tooltip_'
      ) {
        setShowMyProfile(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleProfileClick = () => {
    setShowMyProfile((prevShowMyProfile) => !prevShowMyProfile);
  };

  return (
    <header className="AuthHeader Header">
      <section
        className={`avatar ${showMyProfile && 'border_lk'}`}
        onClick={handleProfileClick}
      >
        <img src={userDatas.image} className="image_avatar" />
        <div className="tooltip_">{userDatas.fullname}</div>
      </section>
      {showMyProfile && (
        <section className="viewMyRpfile_" ref={profileRef}>
          <div className="cnt_first">
            <div className="cnt_modalImg">
              <img src={userDatas.image} alt="" className="modal_img" />
            </div>
            <div className="cnt_info">
              <p className="full_modal">{userDatas.fullname}</p>
              <p className="userType_modal">{userDatas.userType}</p>
              <p className="email_modal">{userDatas.email}</p>
            </div>
          </div>
          <button
            className="btn_closeSesion"
            onClick={() => handleLogOut()}
          >
            Cerrar sesión
          </button>
        </section>
      )}
    </header>
  );
};

export default Header;

