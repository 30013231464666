// styles
import { useState } from "react";
import "./AssignedBody.scss";
import ImageModal from "./ImageModal/ImageModal";

export default function ({ image, textInfo, enable = true }) {

  const [viewImage, setviewImage] = useState(false)

  const handleView = () => {
    setviewImage(true)
  }

  return (
    <div className="bloc_kImg">
      {textInfo ? (
        <div>
          <div className="cnt_img-body" onClick={() => enable ? handleView : null}>
            <img src={image} className="AssignedBody" />
          </div>
          <label>{textInfo}</label>
        </div>
      ):(
        <div className="cnt_img-body" onClick={() => enable ? handleView : null}>
          <img src={image} className="AssignedBody" />
        </div>
      )}
      {viewImage && <ImageModal image={image} setValue={setviewImage}/>}
    </div>
  );
}
