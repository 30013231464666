import React from 'react'

import "./ImageModal.scss"

const ImageModal = ({image, setValue}) => {
  return (
    <div className='ImageModal_' onClick={() => setValue(false)}>
        <div className='insideImageModal1_'>
            <img src={image} alt='' className='ima__'/>
        </div>
    </div>
  )
}

export default ImageModal