import React from 'react'

import "./Footer.scss"

const Footer = () => {
  return (
    <footer className='AuthFooter footer_'>
        <div className="inside_footer">
            Revista internacional de ciencias de la educación temachtiani
        </div>
    </footer>
  )
}

export default Footer