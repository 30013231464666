import React from 'react'

//Styles
import "./NewAdmin.scss"
import Card from '../../../components/atoms/Card/Card'
import Back from '../../../components/atoms/Back/Back'
import { Skeleton } from 'primereact/skeleton'

const NewAdminSkeleton = () => {

  return (
    <Card classNameInside='NewAdmin__'>
        <Back className={"b_tn_back"} skeleton/>
        <div className='btm_cnt'>
            <Skeleton width='200px' height='30px'/>
            <Skeleton width='230px' height='48px'/>
        </div>
            <div className='cnt_datas'>
                <div className='navb_cehck'>
                    <Skeleton shape='circle' width='168px' height='168px'/>
                    <div className='cnt_checkBox'>
                        {[...Array(2)].map((_, idx) => (
                            <div key={idx} style={{display: 'flex', gap: 10, alignItems: "center"}}>
                                <Skeleton width='20px' height='20px'/>
                                <Skeleton width='50px' height='10px'/>
                            </div>
                        ))}
                    </div>
                </div>
                {[...Array(4)].map((_, idx) => (
                    <div key={idx} style={{display: "flex", flexDirection: "column", gap: 8}}>
                        <Skeleton width='80px' height='10px'/>
                        <Skeleton width='100%' height='48px'/>
                    </div>
                ))}
            </div>
    </Card>
  )
}

export default NewAdminSkeleton