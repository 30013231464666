// styles
import "./ActionsBody.scss"

// assets
import eye from '../../../../assets/images/eye.png'
import pencil from "../../../../assets/images/pencil.png"
import trash from "../../../../assets/images/trash.png"
// import check from "../../../../assets/icons/check.svg"
// import cancel from "../../../../assets/icons/cancel.svg"
// import calendar from "../../../../assets/icons/Calendar.svg"
// import message from "../../../../assets/icons/message.svg"

// components
import React from "react"
import { Button } from "primereact/button";
import { useNavigate, useParams } from "react-router-dom"




export default function({onDelete, actions, url, urlEdit, id}) {
    const navigate = useNavigate()
    const navigateFunction = () => { navigate(url) }
    const navigateEdit = () => { navigate(urlEdit) }
    
    return(
        <div className="ActionsBody">
            {
                actions?.edit &&
                <Button  tooltip="Editar" onClick={navigateEdit} tooltipOptions={{className: "actionsTooltip", position: "top"}}>
                    <img className="edit" src={pencil} />
                </Button>
            }

            {
                actions?.view &&
                <Button tooltip="Ver" onClick={navigateFunction} tooltipOptions={{className: "actionsTooltip", position: "top"}}>
                    <img className="show" src={eye}/>
                </Button>
            }
            {
                actions?.delete &&
                <Button tooltip="Eliminar" onClick={() => onDelete()} tooltipOptions={{className: "actionsTooltip", position: "top"}}>
                    <img className="delete" src={trash} />
                </Button>
            }
        </div>
    )
}