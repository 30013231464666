import './CreateArticle.scss'
import ExteriorCard from '../../../components/atoms/ExteriorCard/ExteriorCard';
import Header from "../../../components/atoms/Header/Header";
import Input from "../../../components/atoms/Input/Input";
import InteriorCard from '../../../components/atoms/InteriorCard/InteriorCard';
import { Skeleton } from "primereact/skeleton";
import Button from '../../../components/atoms/Button/Button';

const CreateArticleSkeleton = ({show}) => {
    return (
        <>
            <div className="CreateArticle">
                <ExteriorCard>
                    {/* <Back className={"_back_"} /> */}
                    <Skeleton shape="circle" size="2rem" className="_back_"></Skeleton>
                    <Header skeleton button />
                    <InteriorCard className={"cardInteriorCreateArticle"}>
                        <div className="grid-patern-CreateArticle">
                            <div className="col1">
                                <div>

                                    <Skeleton height="175px"></Skeleton>
                                    {/* <div className="wordName">{word?.name}</div> */}
                                </div>
                            </div>
                            <div className="col2">
                                {/* <div className="minititle">Fecha de publicación</div> */}
                                <Skeleton height="18px" width="100px" className="minititle"></Skeleton>
                                <Input skeleton ></Input>
                                <div className="description">
                                    <Skeleton height="35px"></Skeleton>
                                </div>
                            </div>
                            <div className="col3">
                                <div className="articleName-container">
                                    <Input skeleton ></Input>
                                    <div className="button-contaier">
                                        {
                                            !show ?
                                                <>
                                                    <Button skeleton></Button>
                                                    <Button skeleton></Button>
                                                </> :
                                                <>
                                                    <Button skeleton></Button>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col4">

                                {/* <div className="minititle">Resumen</div> */}
                                <Skeleton height="18px" width="70px" className="minititle"></Skeleton>
                                {/* <textarea className="textArea-createArticle" id="resume" onChange={(e)=>{UpdateValue(e,"resume",inputList,setInputList)}}></textarea> */}
                                <Skeleton className="textArea-createArticle"></Skeleton>

                            </div>
                        </div>
                    </InteriorCard>
                </ExteriorCard>
            </div>
        </>
    )
}
export default CreateArticleSkeleton