import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
const ShowArticle = lazy(() => import("../../../pages/ArticleControl/ShowArticle/ShowArticle"));
const ArticleControl = lazy(() => import("../../../pages/ArticleControl/ArticleControl"));

const ArticleControlRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/article-control"
        element={
          <Suspense fallback={<></>}>
            <ArticleControl />
          </Suspense>
        }
      />
       <Route
        exact
        path="/article-control/:id/show"
        element={
          <Suspense fallback={<></>}>
            <ShowArticle show/>
          </Suspense>
        }
      />
    </Routes>
  );
};

export default ArticleControlRoutes;
