import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import MainMagazine from "../../../pages/MainMagazine/MainMagazine";
// const MagazineCreate = lazy(()=>import('../../../pages/Magazine/MagazineCreate/MagazineCreate')) 
import MagazineCreateSkeleton from '../../../pages/Magazine/MagazineCreate/MagazineCreateSkeleton'
const CreateNumber = lazy(()=>import("../../../pages/Magazine/MagazineCreate/MagazineCreate"));
const MagazineRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path="/journals"
                    element={
                        <Suspense fallback={<></>}>
                            <MainMagazine/>
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/journals/create"
                    element={
                        <Suspense fallback={<MagazineCreateSkeleton/>}>
                            <CreateNumber/>
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/journals/:id/edit"
                    element={
                        <Suspense fallback={<MagazineCreateSkeleton/>}>
                            <CreateNumber edit/>
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/journals/:id/show"
                    element={
                        <Suspense fallback={<MagazineCreateSkeleton show/>}>
                            <CreateNumber show/>
                        </Suspense>
                    }
                />
            </Routes>
        </>
    )
}
export default MagazineRoutes