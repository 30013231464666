import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NewAdminSkeleton from "../../../pages/Administrator/NewAdmin/NewAdminSkeleton";
import AdministratorSkeleton from "../../../pages/Administrator/AdministratorSkeleton";
const EditAdmin = lazy(() => import("../../../pages/Administrator/EditAdmin/EditAdmin"));
const NewAdmin = lazy(() => import("../../../pages/Administrator/NewAdmin/NewAdmin"));
const Administrator = lazy(() => import("../../../pages/Administrator/Administrator"));

const AdministratorRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/administrators"
        element={
          <Suspense fallback={<AdministratorSkeleton />}>
            <Administrator />
          </Suspense>
        }
      />
      <Route
        exact
        path="/administrators/new"
        element={
          <Suspense fallback={<NewAdminSkeleton />}>
            <NewAdmin />
          </Suspense>
        }
      />
      <Route
        exact
        path="/administrators/:id/edit"
        element={
          <Suspense fallback={<NewAdminSkeleton />}>
            <EditAdmin />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AdministratorRoutes;
