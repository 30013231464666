import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import Metrics from "../../../pages/Metrics/Metrics";

const MetricsRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          exact
          path="/dashboard"
          element={
            <Suspense fallback={<div></div>}>
              <Metrics></Metrics>
            </Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default MetricsRoutes
