import React, { createContext, useState, useContext } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [userData, setUserData] = useState();

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider
      value={{
       userData,
       setUserData
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
