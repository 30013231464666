// styles
import "./imgBody.scss";

export default function ({ assigned }) {
  return (
    <div className="bloc_kImg">
      <div className="cnt_img-body">
        <img src={assigned} className="imgBody" />
      </div>
    </div>
  );
}
