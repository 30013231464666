import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NewCommitteeSkeletons from "../../../pages/Committee/NewCommittee/NewCommitteeSkeletons";
import CommitteeSkeletons from "../../../pages/Committee/CommitteeSkeletons";
import Error404 from "../../../pages/Error404/Error404";
const EditCommittee = lazy(() => import("../../../pages/Committee/EditCommittee/EditCommittee"));
const NewCommittee = lazy(() => import("../../../pages/Committee/NewCommittee/NewCommittee"));

const Committee = lazy(() => import("../../../pages/Committee/Committee"));

const CommitteeRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/committee"
        element={
          <Suspense fallback={<CommitteeSkeletons />}>
            <Committee />
          </Suspense>
        }
      />
      <Route
        exact
        path="/committee/new"
        element={
          <Suspense fallback={<NewCommitteeSkeletons />}>
            <NewCommittee />
          </Suspense>
        }
      />
      <Route
        exact
        path="/committee/:name/edit"
        element={
          <Suspense fallback={<NewCommitteeSkeletons/>}>
            <EditCommittee />
          </Suspense>
        }
      />

      {/* Este error debe de estar al final de todos los <Route /> */}
      {/* <Route path="*" element={ <Error404 /> } /> */}
    </Routes>
  );
};

export default CommitteeRoutes;
