import React from 'react';
import Button from '../../atoms/Button/Button';
import './Header.scss'
import { Skeleton } from 'primereact/skeleton';
import { useNavigate } from 'react-router-dom';
import AnimationLoading from '../AnimationLoading/AnimationLoading';

const Header = ({ category, title, button, onClick, skeleton, newArticle, prev, className, loading }) => {
  const navigate = useNavigate()
  return (
    <>
      {
        !skeleton ?
          <>
            <div className={`mb-10 p-3 ${className} ${button ? "headerflex" : ""} ${newArticle && "articles"}`}>
              <div>
                <h2 className="text-4xl dark:text-gray-400">{category}</h2>
                <h1 className="centerText text-3xl font-extrabold tracking-tight dark:text-white text-black">
                  {title}
                </h1>
              </div>
              <div>
                {
                  button ?
                    <>
                      <Button className={"btn_primary buttonsize"} onClick={onClick} title={button}></Button>
                    </> : ""
                }
                {
                  newArticle ?
                    <div className="buttons">
                      <Button className={"btn_primary buttonsize redColor"} onClick={() => navigate(prev)} title={"Cancelar"}></Button>
                      {loading ? (
                        <AnimationLoading className={'w-350'}/>
                      ) : (
                        <Button className={"btn_primary buttonsize"} onClick={onClick} title={newArticle}></Button>

                      )}
                    </div> : ""
                }
              </div>
            </div>
          </>
          :
          <>
            <div className={`mb-10 p-3 ${button ? "headerflex" : ""}`}>
              <div>
                {/* <h2 className="text-4xl dark:text-gray-400">{category}</h2> */}
                <h1 className="centerText text-3xl font-extrabold tracking-tight dark:text-white text-black">
                  <Skeleton width='250px' height='36px'></Skeleton>
                  {/* {title} */}
                </h1>
              </div>
              <div>
                {
                  button ?
                    <>
                      <Skeleton width='250px' height='36px'></Skeleton>
                    </> : ""
                }
              </div>
            </div>
          </>
      }
    </>
  )
}

export default Header;