import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ShowAcademicSkeleton from "../../../pages/Academics/ShowAcademic/ShowAcademicSkeleton";
import AdministratorSkeleton from "../../../pages/Administrator/AdministratorSkeleton";
const ShowAcademic = lazy(() => import("../../../pages/Academics/ShowAcademic/ShowAcademic"));
const Academics = lazy(() => import("../../../pages/Academics/Academics"));

const AcademicRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/academics"
        element={
          <Suspense fallback={<AdministratorSkeleton />}>
            <Academics />
          </Suspense>
        }
      />
      <Route
        exact
        path="/academics/:id/show"
        element={
          <Suspense fallback={<ShowAcademicSkeleton />}>
            <ShowAcademic />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AcademicRoutes;
