import React from 'react'
import { Skeleton } from 'primereact/skeleton'

//Styles
import "./NewEvents.scss"

//Components
import Card from '../../../components/atoms/Card/Card'
import Back from '../../../components/atoms/Back/Back'

const NewEventsSkeletons = () => {   
  return (
    <Card className='NewEvents' classNameInside={"inside_card"}>
        <Back className={"b_tn_back"} skeleton/>
        <div className='heasder_'>
            <Skeleton width='150px' height='30px'/>
            <Skeleton width='250px' height='48px'/>
        </div>
        <div className='cnt_inputs_'>
            {[...Array(3)].map((_, id) => (
                <div key={id} style={{display: "flex", flexDirection: "column", gap: 8}}>
                    <Skeleton width='120px' height='15px'/>
                    <Skeleton width='100%' height='48px'/>
                </div>
            ))}
        </div>
    </Card>
  )
}

export default NewEventsSkeletons