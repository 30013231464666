import axios from "axios";

const urlApi = process.env.REACT_APP_API_URL
export const getData = async(setData)=>
{
    await axios.get(`${urlApi}/api/numbers?populate=*`).then((res)=>
    {
        let data = []
        res.data.data.map((item,key)=>
        {
            const fecha = new Date(item.attributes.createdAt)
            const reader = new FileReader();
            data.push({
                id: item.id,
                assigned: urlApi+item.attributes.img.data.attributes.url,
                name: item.attributes.dataNumber.name,
                date: fecha.toLocaleDateString(),
                actions: {
                    view: true,
                    edit: true,
                    delete: true,
                },
            })
        })
        setData(data)
    })
}
export const  deleteNumber = async (id,setUpdate,update)=>
{
    await axios.delete(`${urlApi}/api/numbers/${id}`).then(() => {
        setUpdate(!update);
      });
}