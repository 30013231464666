import React from "react";
import './RecoverAccount.scss'
import LoginCard from "../../components/atoms/LoginCard/LoginCard";
import Input from "../../components/atoms/Input/Input";
import Button from "../../components/atoms/Button/Button";
const RecoverAccountSkeleton = () => {
    return (
        <>
            <div className="RecoverAccount">
                <LoginCard skeleton logo>
                    <div className="inputContainer">
                        <Input skeleton></Input>
                    </div>
                    <div className="buttonContainer">
                        <Button skeleton className={"btn_cancel"} title={"Cancelar"} onClick={()=>{navigate("/login")}}></Button>
                        <Button skeleton className={"btn_primary"} title={"Enviar código"} onClick={()=>{validationFunction()}}></Button>
                    </div>
                </LoginCard>
            </div>
        </>
    )
}
export default RecoverAccountSkeleton