import React, { Suspense } from 'react'
import { lazy } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import LoginSkeleton from '../../../pages/Login/LoginSkeleton'
import RecoverAccountSkeleton from '../../../pages/RecoverAccount/RecoverAccountSkeleton'
import VerificationCodeSkeleton from '../../../pages/VerificationCode/VerificationCodeSkeleton'
import NewPasswordSkeleton from '../../../pages/NewPassword/NewPasswordSkeleton'
import { useEffect } from 'react'
import { useState } from 'react'
const RecoverAccount =lazy(()=>import('../../../pages/RecoverAccount/RecoverAccount')) 
const VerificationCode =lazy(()=>import('../../../pages/VerificationCode/VerificationCode')) 
const NewPassword =lazy(()=>import('../../../pages/NewPassword/NewPassword')) 
const Login = lazy(()=>import('../../../pages/Login/Login')) 


const LoginRoutes = ({setAuth}) => {

    const navigate = useNavigate()
    const auth = localStorage.getItem("auth")

    useEffect(() => {
      if(auth && auth === "true"){
        navigate("/dashboard")
      }
      else{
        navigate("/")
      }
    }, [])

    return (
      <>
        <Routes>
          <Route
            exact
            path="/"
            element={
                <Suspense fallback={<LoginSkeleton />}>
                  <Login></Login>
                </Suspense>
            }
          />
          <Route
          exact
            path="/recover-account"
            element={
              <Suspense fallback={<RecoverAccountSkeleton />}>
                <RecoverAccount></RecoverAccount>
              </Suspense>
            }
          />
          <Route
            path="/verification-code"
            element={
              <Suspense fallback={<VerificationCodeSkeleton />}>
                <VerificationCode></VerificationCode>
              </Suspense>
            }
          />
          <Route
            path="/new-password"
            element={
              <Suspense fallback={<NewPasswordSkeleton />}>
                <NewPassword setAuth={setAuth}></NewPassword>
              </Suspense>
            }
          />
        </Routes>
      </>
    );
}

export default LoginRoutes