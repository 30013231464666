import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import CurrentIssueSkeletons from "../../../pages/CurrentIssue/CurrentIssueSkeletons";
import SpecialNumberSkeletons from "../../../pages/SpecialNumber/SpecialNumberSkeletons";
import CreateArticleSkeleton from "../../../pages/CurrentIssue/CreateArticle/CreateArticleSkeleton";
import EditArticleSkeleton from "../../../pages/CurrentIssue/EditArticle/EditArticleSkeleton";


//components
const CurrentIssue = lazy(() => import("../../../pages/CurrentIssue/CurrentIssue"));
const CreateArticle = lazy(() => import("../../../pages/CurrentIssue/CreateArticle/CreateArticle"));
const EditArticle = lazy(() => import("../../../pages/CurrentIssue/EditArticle/EditArticle"));
const SpecialNumber = lazy(() => import("../../../pages/SpecialNumber/SpecialNumber"));
const CreateArticleS = lazy(() => import("../../../pages/SpecialNumber/CreateArticleS/CreateArticleS"));
const EditArticleS = lazy(() => import("../../../pages/SpecialNumber/EditArticleS/EditArticleS"));

//skeleton


const CurrentIssueRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/current-numbers"
        element={
          <Suspense fallback={<CurrentIssueSkeletons />}>
            <CurrentIssue />
          </Suspense>
        }
      />
      <Route
        exact
        path="/current-numbers/special-number"
        element={
          <Suspense fallback={<SpecialNumberSkeletons />}>
            <SpecialNumber />
          </Suspense>
        }
      />
      <Route
        exact
        path="/current-numbers/create/:id"
        element={
          <Suspense fallback={<CreateArticleSkeleton />}>
            <CreateArticle />
          </Suspense>
        }
      />
      <Route
        exact
        path="/current-numbers/:id/edit"
        element={
          <Suspense fallback={<EditArticleSkeleton />}>
            <EditArticle />
          </Suspense>
        }
      />
      <Route
        exact
        path="/current-numbers/special-number/new"
        element={
          <Suspense fallback={<CreateArticleSkeleton />}>
            <CreateArticleS />
          </Suspense>
        }
      />
      <Route
        exact
        path="/current-numbers/special-number/:id/edit"
        element={
          <Suspense fallback={<EditArticleSkeleton />}>
            <EditArticleS />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default CurrentIssueRoutes;
