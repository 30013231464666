import React, { useEffect, useState } from "react";

//Assets
import perfil from '../../assets/images/Oval.png'

//styles
import "./MainMagazine.scss";
import Card from "../../components/atoms/Card/Card";
import InputSearch from "../../components/atoms/InputSearch/InputSearch";
import Button from "../../components/atoms/Button/Button";
import TableCardX from "../../components/organims/TableCard/TableCardX";
import ActionsBody from "../../components/organims/TableCard/ActionsBody/ActionsBody";
import { NavLink } from "react-router-dom";
import magazine_0 from '../../assets/images/magazine_0.png'
import imgBody from "../../components/organims/TableCard/imgBody/imgBody";
import { deleteNumber, getData } from "./api";
import { Encrypt } from "../../Hooks/Hooks";
import { QuestionModal } from "../../components/molecules/modals/Modals";
const MainMagazine = () => {
  
  const [update,setUpdate]=useState(false)
    const actions = ({actions, id, name}) => {
        const idEncipt = Encrypt(id) 
        // setidGeneral(idEncipt)
        return(
            // ---------------------------------------------------------------------- API -------------------- cuando este la api se tiene que modificar la fucnio de eliminar 
            <ActionsBody actions={actions} url={`${idEncipt}/show`} urlEdit={`${idEncipt}/edit`} id={id} setData={setData} onDelete={()=>onDelete(id)}/>
        )    
    }

  
    const onDelete = async(id)=>
    {
      const res = await QuestionModal("Desea ELIMINAR este número","")
      
      if(res.isConfirmed)
      {
        deleteNumber(id,setUpdate,update)
      }
    }

    const columns = [
        {
            id: 1,
            field: "Image",
            header: "Imagen",
            w: "43px",
            body: imgBody,
        },
        {
            id: 2,
            field: "name",
            header: "Titulo de la revista",
            w: "180px",
        },
        {
            id: 3,
            field: "date",
            header: "Fecha de publicación",
            w: "110px",
        },
        {
            id: 4,
            field: "actions",
            header: "Acciones",
            w: "100px",
            body: actions,
        },
        
      ];
      const [data,setData] = useState()
      const [data_list, setData_list] = useState(data)
      const [cantList, setCantList] = useState(false)

         
    
      const [input_text, setInput_text] = useState(null)
    //   const [foundResults, setFoundResults] = useState(true);
      
      useEffect(() => {
        setData((prevMagazine) => {
          const filteredMagazine = !input_text
            ? data_list
            : prevMagazine.filter((title) =>
                title.name.toLowerCase().includes(input_text.toLowerCase())
              )
        //   setFoundResults(filteredMagazine.length > 0);
          return filteredMagazine;
        })
      }, [input_text]);
      

      //integracions
      
      useEffect(() => {
        if(data)
        {
            if(data.length > 5){
                setCantList(true)
            }
        }
      }, [data])  
      
      useEffect(()=>
      {
            getData(setData)
      },[update])

  return (
    <Card className={"MainMagazine_"} classNameInside={"inside_MainJournal"}>
        <div className="header_table">
            <h1>Revistas completas</h1>
            <div className="cnt_btn-search">
                <InputSearch placeholder={"Buscar revista"} onChange={(e) => setInput_text(e.target.value)}/>
                {/* <NavLink to={"create"} style={{width: "100%"}}>
                    <Button title={"Subir nueva revista"} className={"btn_primary"}/>
                </NavLink> */}
            </div>
        </div>
        <div className="cnt_table">
          <TableCardX data={data} columns={columns} numberRows={4} paginator={cantList}/>
        </div>
    </Card>
  );
};

export default MainMagazine;
