import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NewEventsSkeletons from "../../../pages/NextEvents/NewEvents/NewEventsSkeletons";
import NextEventsSkeleton from "../../../pages/NextEvents/NextEventsSkeleton";
const EditEvents = lazy(() => import("../../../pages/NextEvents/EditEvents/EditEvents"));
const NewEvents = lazy(() => import("../../../pages/NextEvents/NewEvents/NewEvents"));
const NextEvents = lazy(() => import("../../../pages/NextEvents/NextEvents"));

const NextEventsRoutes = () => {
    return (
            <Routes>
                <Route
                    exact
                    path="/next-events"
                    element={
                        <Suspense fallback={<NextEventsSkeleton />}>
                            <NextEvents />
                        </Suspense>
                    }
                />
                <Route
                    exact
                    path="/next-events/new"
                    element={
                        <Suspense fallback={<NewEventsSkeletons />}>
                            <NewEvents />
                        </Suspense>
                    }
                />
                  <Route
                    exact
                    path="/next-events/:id/edit"
                    element={
                        <Suspense fallback={<NewEventsSkeletons />}>
                            <EditEvents />
                        </Suspense>
                    }
                />
            </Routes>
    )
}
export default NextEventsRoutes