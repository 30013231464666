import React from 'react'

//Styles
import "./Card.scss"

const Card = ({children, className, classNameInside}) => {
  return (
    <div className={`AuthView main_card ${className}`}>
      <div className={`Card_ ${classNameInside}`}>
          {children}
      </div>
    </div>
  )
}

export default Card