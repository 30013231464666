import React from 'react'

//Styles
import "./NextEvents.scss"
import Card from '../../components/atoms/Card/Card'
import TableCardX from '../../components/organims/TableCard/TableCardX'
import { useState } from 'react'
import ActionsBody from '../../components/organims/TableCard/ActionsBody/ActionsBody'
import { Skeleton } from 'primereact/skeleton'

const NextEventsSkeleton = () => {
    const actions = ({ actions, id }) => {
        return <ActionsBody actions={actions} urlEdit={`${id}/edit`} onDelete={() => handleDelete(id)} />;
    };

    const [dataTmp, setDataTmp] = useState( [
        {
            id: 1,
            events: "Paseo matutino",
            date: "Camping",
            siteurl: "Viaje a pie, Aventura...",
            actions: {
                view: true,
                delete: true,
            },
        },
        {
          id: 1,
          events: "Paseo matutino",
          date: "Camping",
          siteurl: "Viaje a pie, Aventura...",
          actions: {
              view: true,
              delete: true,
          },
        },
        {
          id: 1,
          events: "Paseo matutino",
          date: "Camping",
          siteurl: "Viaje a pie, Aventura...",
          actions: {
              view: true,
              delete: true,
          },
      },
      {
        id: 1,
        events: "Paseo matutino",
        date: "Camping",
        siteurl: "Viaje a pie, Aventura...",
        actions: {
            view: true,
            delete: true,
        },
      },
      {
        id: 1,
        events: "Paseo matutino",
        date: "Camping",
        siteurl: "Viaje a pie, Aventura...",
        actions: {
            view: true,
            delete: true,
        },
    },
    {
      id: 1,
      events: "Paseo matutino",
      date: "Camping",
      siteurl: "Viaje a pie, Aventura...",
      actions: {
          view: true,
          delete: true,
      },
    },
 
      ])

    const columns = [
        {
          id: 1,
          field: "events",
          header: "Evento",
        },
        {
          id: 2,
          field: "date",
          header: "Fecha",
        },
        {
          id: 3,
          field: "siteurl",
          header: "url",
        },
        {
          id: 4,
          field: "actions",
          header: "Acciones",
          body: actions,
        },
      ];

  return (
    <Card className='NextEvents_' classNameInside={"inside_NextEvents_"}>
        <div className="header_table">
            <Skeleton width='250px' height='30px'/>
            <div className="cnt_btn-search">
                <Skeleton width='100%' height='48px'/>
                <Skeleton width='100%' height='48px'/>
            </div>
        </div>

        <TableCardX data={dataTmp} columns={columns} skeleton/>
    </Card>
  )
}

export default NextEventsSkeleton