import React, { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import NewQuestionSkeletons from "../../../pages/Legals/NewQuestion/NewQuestionSkeletons";

//Lazy
const ShowLegals = lazy(() => import("../../../pages/Legals/ShowLegals/ShowLegals"));
const EditQuestion = lazy(() => import("../../../pages/Legals/EditQuestion/EditQuestion"));
const NewQuestion = lazy(() => import("../../../pages/Legals/NewQuestion/NewQuestion"));
const Legals = lazy(() => import("../../../pages/Legals/Legals"));

//components

//skeleton


const LegalsRoutes = () => {
  return (
    <Routes>
        <Route
            exact
            path="/legals"
            element={ 
                <Suspense fallback={<></>}>
                    <Legals />
                </Suspense>
            }
        />
        <Route
            exact
            path="/legals/create"
            element={ 
                <Suspense fallback={<NewQuestionSkeletons />}>
                    <NewQuestion />
                </Suspense>
            }
        />
        <Route
            exact
            path="/legals/:id/edit"
            element={ 
                <Suspense fallback={<NewQuestionSkeletons />}>
                    <EditQuestion />
                </Suspense>
            }
        />
        <Route
            exact
            path="/legals/:id/show"
            element={ 
                <Suspense fallback={<NewQuestionSkeletons />}>
                    <ShowLegals />
                </Suspense>
            }
        />
    </Routes>
  );
};

export default LegalsRoutes;
