import React from "react";
import './NewPassword.scss'
import LoginCard from "../../components/atoms/LoginCard/LoginCard";
import InputPassword from "../../components/atoms/InputPassword/InputPassword";
import Button from "../../components/atoms/Button/Button";

const NewPasswordSkeleton = () => {
    
    return (
        <>
            <div className="NewPassword">
                <LoginCard skeleton logo>
                    <div className="inputs-container">
                        <InputPassword skeleton></InputPassword>
                        <InputPassword skeleton></InputPassword>
                    </div>
                    <Button skeleton></Button>
                </LoginCard>
            </div>
        </>
    )
}
export default NewPasswordSkeleton