import React from 'react'
import "./Administrator.scss"
import Card from '../../components/atoms/Card/Card'
import AssignedBody from "../../components/organims/TableCard/AssignedBody/AssignedBody";
import TableCardX from '../../components/organims/TableCard/TableCardX'
import { useState } from 'react'
import { Skeleton } from 'primereact/skeleton'

const AdministratorSkeleton = () => {
    const [dataTmp, setDataTmp] = useState( [
      {
          id: 1,
          profile: null,
          fullname: "Paseo matutino",
          evaluator: "Camping",
          email: "Viaje a pie, Aventura...",
          country: "Español",
          actions: {
              view: true,
              delete: true,
          },
      },
      {
        id: 2,
        profile: null,
        fullname: "Paseo matutino",
        evaluator: "Camping",
        email: "Viaje a pie, Aventura...",
        country: "Español",
        actions: {
            view: true,
            delete: true,
        },
    },
    {
        id: 3,
        profile: null,
        fullname: "Paseo matutino",
        evaluator: "Camping",
        email: "Viaje a pie, Aventura...",
        country: "Español",
        actions: {
            view: true,
            delete: true,
        },
    },
    {
        id: 4,
        profile: null,
        fullname: "Paseo matutino",
        evaluator: "Camping",
        email: "Viaje a pie, Aventura...",
        country: "Español",
        actions: {
            view: true,
            delete: true,
        },
    },
    {
        id: 5,
        profile: null,
        fullname: "Paseo matutino",
        evaluator: "Camping",
        email: "Viaje a pie, Aventura...",
        country: "Español",
        actions: {
            view: true,
            delete: true,
        },
    },
    ])

    const columns = [
        {
          id: 1,
          field: "photo",
          header: "Foto",
          w: "16%",
          body: AssignedBody,
        },
        {
          id: 2,
          field: "fullname",
          header: "Nombre",
          w: "16%",
        },
        {
          id: 3,
          field: "email",
          header: "Correo",
          w: "16%",
        },
        {
          id: 4,
          field: "evaluator",
          header: "Evaluadores de contenido",
          w: "16%",
        },
        {
          id: 5,
          field: "country",
          header: "País",
          w: "16%",
        },
        {
          id: 6,
          field: "actions",
          header: "Acciones",
          w: "16%",
        //   body: actions,
        },
    ];

    return (
        <Card classNameInside={"Administrator_inside"} className={"Administrator_"}>
            <div className="header_table">
                <Skeleton width='200px' height='40px'/>
                <div className="cnt_btn-search">
                    <Skeleton width='100%' height='40px'/>
                    <Skeleton width='100%' height='40px'/> 
                </div>
            </div>
            <div className="cnt_table">
                <TableCardX data={dataTmp} skeleton columns={columns} numberRows={5}/>
            </div>
        </Card>
    )
}

export default AdministratorSkeleton